import "./v4/ads/ad-manager.js";
//import {loadCarousel} from  "./v4/swipe-handler";
//window.loadCarousel = loadCarousel;


import Cookies from "./v4/js.cookie.js";
window.Cookies = Cookies;
window.TWT_IS_SUBSCRIBER = Cookies.get('onaip_') === '';


function twt_add_subscriber_display_flags() {
    const sheet = document.styleSheets.item(0);
    sheet.insertRule('.twt-subscriber-hide{display: none;}', sheet.cssRules.length);
    sheet.insertRule('.twt-subscriber-show{display: block;}', sheet.cssRules.length);
    console.debug('Added subscriber display flags');
}

console.log('User is subscriber?', TWT_IS_SUBSCRIBER);

if( TWT_IS_SUBSCRIBER ) {
    twt_add_subscriber_display_flags();
}
